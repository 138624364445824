<template>
  <div>
    <HeaderBanner
      :title="headerText.title"
      :small="headerText.small"
      :img="headerText.img"
    ></HeaderBanner>
    <LeftImg
      :title="div1.title"
      :small="div1.small"
      :text1="div1.text1"
      :text2="div1.text2"
      :text3="div1.text3"
      :img="div1.img"
    ></LeftImg>
    <RightImg
      :title="div2.title"
      :small="div2.small"
      :text1="div2.text1"
      :text2="div2.text2"
      :text3="div2.text3"
      :img="div2.img"
    ></RightImg>
    <LeftImg
      :title="div3.title"
      :small="div3.small"
      :text1="div3.text1"
      :text2="div3.text2"
      :text3="div3.text3"
      :img="div3.img"
    ></LeftImg>
    <FormEnd></FormEnd>
  </div>
</template>

<script>
import HeaderBanner from "../components/headerBanner.vue";
import LeftImg from "../components/leftImg.vue";
import RightImg from "../components/rightImg.vue";
export default {
  name: "put",
  components: { HeaderBanner, LeftImg, RightImg },
  data() {
    return {
      headerText: {
        title: "博创时空投放营销解决方案",
        small: "聚合全网海量APP用户，让投放获客更高效",
        img: require("../assets/imgs/put/banner.png"),
      },
      div1: {
        title: "丰富的媒体流量",
        small: "多渠道覆盖媒体流量资源，占据用户碎片时间",
        text1: "资讯类：今日头条、搜狐新闻、网易新闻...",
        text2: "工具类：WPS、Wifi万能钥匙、简书...",
        text3: "生活类：美团、爱奇艺、搜狐视频...",
        img: require("../assets/imgs/put/app.png"),
      },
      div2: {
        title: "多样的广告类型",
        small: "多样化触达用户生活场景，提升用户转化概率",
        text1: "开屏广告增强品牌记忆",
        text2: "信息流广告提高用户转化率",
        text3: "Banner广告制作简单快速铺量",
        img: require("../assets/imgs/put/diversity.png"),
      },
      div3: {
        title: "精准的算法优化",
        small: "多维度采用独家算法优化，持续优化投放效果",
        text1: "精准匹配行业人群，提高整体转化率",
        text2: "oCPX优化考核项目，稳定成本",
        text3: "一键起量，快速获取目标客户",
        img: require("../assets/imgs/put/suanfa.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>